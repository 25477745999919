.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS Code */
/* Desktop Screen Size */
/* @media (min-width:961px) { */
  li.navbar-item {
    list-style: none;
    margin-right: 5%;
    color: white;
    font-weight: 100;
  }
  img.logo {
    margin-top: -25%;
    width: 46%;
  }
  .navbar {
    display: flex;
    padding: 2%;
    justify-content: center;
    background-color: black;
    z-index: 1;
    position: fixed;
    width: 100%;
  }
  .extra-space-bottom {
    margin-bottom: 7%;
  }
  .footer {
    padding: 2%;
    justify-content: center;
    background-color: black;
  }
  .centered{
    text-align: center;
  }
  .hero-btn {
    background-color: white;
    color: black;
    padding: 0.8% 1%;
    width: 14%;
    margin-bottom: 0.5%;
    border-color: black;
    transition-duration: 1s;
    border-radius: 33px;
  }
  .ds-hide{
    display: none;
  }
  .hero-btn:hover{
    color: white;
    background-color: black;
  }
  .hero h1 {
    font-size: 500%;
    font-weight: 200;
    margin-top: -8%;
    margin-bottom: 2%;
  }
  .hide-obj{
    display: none;
  }
  .show-obj{
    display: block;
  }
  .icon{
    margin-left: 1%;
  }
  .hero h3 {
    font-size: 200%;
    font-weight: 200;
    margin-top: -3%;
    margin-bottom: 5%;
  }
  .hero{
    padding: 12%;
    background-image: url('https://images.unsplash.com/photo-1494825514961-674db1ac2700?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    margin-bottom: 8%;
    padding-top: 20%;
  }
  .row{
    display: flex;
  }
  .middle{
    justify-content: center;
  }
  .abt-box {
    text-align: center;
    width: 15%;
    padding: 2%;
    margin: 1%;
    border-color: black;
    border-style: solid;
    border-width: 3px;
    padding-bottom: 4%;
    padding-top: 4%;
    transition-duration: 1s;
  }
  button.sec-btn {
    background: black;
    color: white;
    padding: 1% 4%;
    font-size: 120%;
    margin-top: 3%;
    border-radius: 43px;
    transition-duration: 1s;
    border: none;
  }
  button.sec-btn:hover{
    background-color: #303633;
    border: none;
    padding: 1% 6%;
  }
  .abt-box:hover{
    background-color: black;
    color: white;
    width: 20%;
  }
  .text-box {
    text-align: center;
    margin-top: 8%;
    width: 80%;
  }
  .img-box {
    width: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  #img-box-1{
    background-image: url('https://images.unsplash.com/photo-1457129962825-adcaea7406c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80');
  }
  #img-box-2{
    background-image: url('https://images.unsplash.com/photo-1457129962825-adcaea7406c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80');
  }
  .text-box {
    text-align: center;
    margin-top: 11%;
    margin-bottom: 11%;
    width: 70%;
    padding: 4%;
  }
  .text-box h1{
    font-size: 313%;
  }
  .text-box p {
    font-size: 131%;
    margin-top: 6%;
    font-weight: 200;
  }
  /* .text-section {
    margin-top: 15%;
    margin-bottom: 15%;
  } */
  .bottom-space{
    margin-bottom: 5%;
  }
  .contact-sec {
    background-image: url(https://images.unsplash.com/photo-1494825514961-674db1ac2700?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
    padding: 3%;
  }
  form.contact-form {
    text-align: center;
    padding: 1%;
  }
  input.contact-box {
    width: 45%;
    padding: 1%;
    margin-bottom: 0.5%;
  }
  .contact-info {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    line-height: 0.5;
  }
  .contact-info h3{
    font-weight: 100;
  }
  .contact-info h4{
    font-weight: 100;
  }
  a:hover{
    text-decoration: underline;
  
  }
  .pressed-btn{
    background-color: beige;
  }
  #submit-btn{
    transition-duration: 1s;
  }
  #submit-btn:hover {
    background-color: #606965;
    border: none;
    color: white;
  }
  
  button{
    cursor: pointer;
  }
  .row-3{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
  }
  .team-box {
    flex: 0 0 19.333333%;
    text-align: center;
    border-style: solid;
    margin: 2%;
    padding: 2%;
    transition-duration: 1s;
  }
  .team-box:hover{
    color: white;
    background: black;
    flex: 0 0 23.333333%;
    border: none;
  }
  .navbar-item a {
    color: white;
    text-decoration: none;
    transition-duration: 1s;
  }
  .space-bottom{
    margin-bottom: 5%;
  }
  .faq-box{
    box-shadow: 8px -1px 15px -3px rgb(0 0 0 / 10%);
    padding: 3%;
    width: 75%;
    margin-left: 3%;
    margin-bottom: 4%;
    border-radius: 22px;
  }
  .answer {
    display: none;
  }
  .reason-box {
    flex: 0 0 26.333333%;
    padding: 2%;
    text-align: center;
    color: white;
    background: black;
    transition-duration: 1s;
  }
  .reason-box:hover{
    color: black;
    background: white;
    flex: 0 0 27.333333%;
  }
  .reasons-sec{
    background: black;
    /* margin-bottom: 15%; */
    padding: 2%;
    padding-bottom: 8%;
    padding-top: 8%;
  }
  .reason-box p {
    font-weight: 200;
  }
  .reason-box h2 {
    font-weight: 600;
  }
  .invest-sec {
    padding: 10%;
  }
  .invest-sec h1 {
    font-size: 400%;
    margin-bottom: 1%;
  }
  html{
    scroll-behavior: smooth;
  }
  .slide{
    display: none;
    background: black;
    color: white;
    padding: 15%;
  }
  .slide.active{
    display: block;
  }
  .slides {
    margin-top: 10%;
  }
  .arrow-nav {
    width: 10%;
    padding: 2%;
    background-color: black;
    margin-top: 10%;
  }
  .reasons-sec h1{
    margin-bottom: 6%;
  }
  .faq-btn {
    position: absolute;
    left: 80%;
  }
  .show-faq{
    display: block;
  }
  .faq-box {
    cursor: pointer;
  }
  html {
    /* To make use of full height of page*/
    min-height: 100%;
    margin: 0;
  }
  .row-slides{
    display: flex;
  }
  .navbar-mb{
    display: none;
  }
  .bg-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

/* Mobile Version Styles */
@media (min-width:320px) and (max-width: 600px) {
  .row{
    display: block;
  }
  .abt-box {
    width: 55%;
    margin-left: 20%;
    margin-bottom: 5%;
  }
  #img-box-1 , #img-box-2 {
    display: none;
  }
  .text-box {
    width: 93%;
    padding: 3%;
  }
  .text-box h1 {
    font-size: 227%;
  }
  .row-3 {
    display: block;
  }
  .invest-sec h1 {
    font-size: 252%;
    margin-bottom: 10%;
  }
  input.contact-box {
    width: 75%;
    padding: 4%;
    margin-bottom: 2.5%;
  }
  .links{
    display: none;
  }
  .hero-btn {
    background-color: white;
    padding: 5%;
    border-color: black;
    border-style: solid;
    /* border: none; */
    color: black;
    width: 50%;
    margin-top: 4%;
  }
  .hero h3 {
    display: none;
  }
  .navbar-item{
    display: none;
  }
  .navbar-mb{
    display: block;
  }
  .navbar {
    display: flex;
    padding: 2%;
    background-color: black;
    position: fixed;
    width: 100%;
    justify-content: unset;
    top: 0%;
    padding-left: 6%;
  }
  h1.centered {
    margin-top: 20%;
  }
  .hero h1 {
    margin-top: -5%;
    font-size: 410%;
  }
  img.logo {
    margin-top: -4%;
    width: 100%;
  }
  .menu-tab {
    position: fixed;
    top: 7%;
    left: 0%;
    background: black;
    color: white;
    padding: 10%;
    width: 100%;
  }
  li.menu-title-mb {
    list-style: none;
    font-size: 150%;
    margin-bottom: 14%;
    color: white;
  }
  .menu-content a {
    text-decoration: blink;
    color: white;
    transition-duration: 1s;
  }
  .menu-content a:hover{
    text-decoration: underline;
  }
  .hide{
    display: none;
  }
  form.contact-form {
    margin-bottom: 26%;
  }
  .contact-sec h1.centered {
    margin-top: 39%;
  }
  #submit-btn {
    margin-bottom: 37%;
    padding: 3% 8%;
    background: white;
    color: black;
    border-color: black;
    border-style: solid;
  }
  .footer {
    padding: 19%;
    padding-top: 25%;
  }
  .faq-box {
    padding: 6%;
    width: 81%;
    margin-bottom: 5%;
  }
  .faq-btn {
    display: none;
  }
  .abt-box:hover {
    background-color: black;
    color: white;
    width: 55%;
  }
}

@media (min-width:580px) and (max-width: 1290px){
  #img-box-1 , #img-box-2 {
    display: none;
  }
}
@media (min-width: 1291px){
  #img-box-1 , #img-box-2 {
    display: block;
  }
  .text-box {
    margin-left: 0%;
  }
}
@media(min-width: 600px) and (max-width: 1290px){
  .text-box {
    margin-left: 11%;
  }
  img.logo {
    margin-top: -21%;
    width: 65%;
  }
}
/* iPads and Tablets */
@media (min-width:700px) and (max-width: 900px) {
  .hero-btn{
    padding: 1.8%;
    width: 21%;
  }
  .abt-box p {
    font-size: 94%;
  }
  .arrow-nav{
    margin-left: -0.3%;
  }
  .img-box{
    display: none;
  }
  .text-box {
    margin-left: 12%;
  }
  .contact-sec {
    padding: 8%;
    padding-bottom: 48%;
  }
  .footer {
    padding: 6%;
  }
}
@media(min-width:100px) and (max-width: 600px){
  .footer {
    padding: 19%;
    padding-top: 25%;
    margin-top: -51%;
  }
  img.logo {
    margin-top: -8%;
    width: 100%;
  }
  .hero h1 {
    margin-top: -5%;
    font-size: 360%;
  }
}
@media(min-width: 1550px){
  .bottom-space {
    margin-bottom: -1%;
  }
  .hero h1 {
    font-size: 500%;
    font-weight: 200;
    margin-top: -7%;
  }
  img.logo {
    margin-top: -12%;
    width: 32%;
  }
  .hero {
    padding: 12%;
  }
}
@media(max-width: 480px){
  .contact-info h3 {
    font-weight: 100;
  }
  .contact-info h4 {
    font-size: x-small;
  }
}